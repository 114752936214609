<template>
  <c-flex flex-dir="column">
    <c-text
      v-if="isPayingClient"
      font-size="3xl"
    >
      Tambah Paying Klien
    </c-text>
    <c-text
      v-else
      font-size="3xl"
    >
      Tambah Klien
    </c-text>

    <ClientForms
      v-model="value"
      v-chakra
      :is-paying="isPayingClient"
      :is-edit="false"
      mt="30px"
    />

    <c-stack
      mt="30px"
      is-inline
      spacing="20px"
      w="100%"
    >
      <c-button
        type="button"
        variant-color="gray"
        rounded="full"
        h="62px"
        w="290px"
        as="router-link"
        :to="{ name: 'superadmin.clients' }"
      >
        Batal
      </c-button>
      <c-button
        type="button"
        variant-color="primary"
        rounded="full"
        h="62px"
        w="290px"
        @click.prevent="submit_()"
      >
        Simpan
      </c-button>
    </c-stack>
  </c-flex>
</template>

<script>
import ClientForms from './forms.vue'

export default {
  name: 'SUClientCreate',
  components: { ClientForms },
  data() {
    return {
      firstName: null,
      lastName: null,
      photoUrl: null,
      photoFile: null,
      birthPlace: null,
      phone: null,
      email: null,
      status: null,
      birthDate: null,
      address: null,
      country: null,
      province: null,
      city: null,
      zipCode: null,
      gender: null,
      selectedProgramId: null,
      selectedServiceId: null,
    }
  },
  computed: {
    isPayingClient() {
      return this.$route.query.paying != null
    },
    value: {
      get() {
        return {
          firstName: this.firstName,
          lastName: this.lastName,
          photoUrl: this.photoUrl,
          birthPlace: this.birthPlace,
          phone: this.phone,
          email: this.email,
          status: this.status,
          birthDate: this.birthDate,
          address: this.address,
          country: this.country,
          province: this.province,
          city: this.city,
          zipCode: this.zipCode,
          gender: this.gender,
          clientType: this.isPayingClient
            ? 'paying_client'
            : 'client',
          productId: this.selectedProgramId,
          productServiceId: this.selectedServiceId,
        }
      },
      set(v) {
        this.firstName = v.firstName
        this.lastName = v.lastName
        this.photoUrl = v.photoUrl
        this.photoFile = v.photoFile
        this.birthPlace = v.birthPlace
        this.phone = v.phone
        this.email = v.email
        this.status = v.status
        this.birthDate = v.birthDate
        this.address = v.address
        this.country = v.country
        this.province = v.province
        this.city = v.city
        this.zipCode = v.zipCode
        this.gender = v.gender
        this.selectedProgramId = v.productId
        this.selectedServiceId = v.productServiceId
      },
    },
  },
  mounted() {
    window.submit = this.submit_
  },
  methods: {
    async submit_() {
      if (this.photoFile != null) {
        let form = new FormData()
        form.set('file', this.photoFile)
        let photoUrl = await this.axios
          .post('/v1/users/upload', form)
          .then((r) => r.data.data.url)
        this.value.photoUrl = photoUrl
      }

      this.value.status = this.value.status ?? 'verified'
      for (let key of Object.keys(this.value)) {
        if (this.value[key] == null) {
          delete this.value[key]
        }
      }

      await this.$store
        .dispatch('suManagementClient/createClient', this.value)
        .then((it) =>
          this.$router.replace({
            name: 'superadmin.clients-detail',
            params: { clientId: it.id },
          }),
        )
        .catch((err) => {
          let data = err.response.data
          this.$toast({
            title: 'Failed',
            description: data.message,
            status: 'error',
            position: 'bottom-right',
          })
        })
    },
  },
}
</script>
